import { useLazyQuery } from '@apollo/client';
import { message, Select } from 'antd';
import React, { useState, useEffect } from 'react';
import {
  BrandedContainer,
  GetBrandedContainerDocument,
} from '../../../resolver.types';
import { confirmIndexChangeModal } from './helpers';
import { IndexStateSelect } from './indexer-switcher.styles';

export type IndexSwitcherProps = {
  initialValue?: string;
  disabled?: boolean;
  brandedContainerId: string;
  brandedContainer?: BrandedContainer;
  onIndexStateChange: (value: boolean) => Promise<void>;
};

enum IndexValues {
  Index = 'index',
  NoIndex = 'no index',
}

const indexStates = [
  {
    value: IndexValues.Index,
    iconClass: 'fa-check-circle',
    iconColor: 'green',
  },
  {
    value: IndexValues.NoIndex,
    iconClass: 'fa-times-circle',
    iconColor: 'red',
  },
];

const mapBooleanToIndexValue = (isIndexable: boolean): IndexValues => {
  return isIndexable ? IndexValues.Index : IndexValues.NoIndex;
};

const mapIndexValueToBoolean = (indexValue: IndexValues): boolean => {
  return indexValue === IndexValues.Index;
};

export const IndexSwitcher: React.FC<IndexSwitcherProps> = ({
  disabled = false,
  brandedContainerId,
  brandedContainer,
  onIndexStateChange,
}) => {
  const [fetchBrandedContainer, { loading, data }] = useLazyQuery(
    GetBrandedContainerDocument,
    {
      variables: { id: brandedContainerId },
    }
  );

  const [selectedIndex, setSelectedIndex] = useState<IndexValues | null>(null);

  useEffect(() => {
    if (brandedContainer) {
      setSelectedIndex(
        mapBooleanToIndexValue(brandedContainer?.isIndexable || false)
      );
    } else {
      fetchBrandedContainer();
    }
  }, [brandedContainer, fetchBrandedContainer]);

  useEffect(() => {
    if (data && data.brandedContainer) {
      setSelectedIndex(
        mapBooleanToIndexValue(data.brandedContainer.isIndexable)
      );
    }
  }, [data]);

  const onChange = async (newIndexValue: any) => {
    confirmIndexChangeModal({
      state: selectedIndex!,
      stateTarget: newIndexValue,
      callback: onOk(newIndexValue),
    });
  };

  const onOk = (indexStateTarget: IndexValues) => async () => {
    const booleanIndexState = mapIndexValueToBoolean(indexStateTarget);
    await onIndexStateChange(booleanIndexState);
    setSelectedIndex(indexStateTarget);
    message.success({
      key: 'update-branded-container-index-state',
      content:
        indexStateTarget === IndexValues.Index
          ? 'Branded container index enabled.'
          : 'Branded container index disabled.',
    });
  };

  return (
    <div style={{ width: 'fit-content' }}>
      <IndexStateSelect
        dropdownMatchSelectWidth={false}
        disabled={loading || disabled}
        onChange={onChange}
        value={selectedIndex}
        optionLabelProp="children"
      >
        {indexStates.map((state) => (
          <Select.Option key={state.value}>
            <span className="option-icon" style={{ marginRight: '10px' }}>
              <i
                style={{ color: state.iconColor }}
                className={`fal ${state.iconClass}`}
              ></i>
            </span>
            {state.value}
          </Select.Option>
        ))}
      </IndexStateSelect>
    </div>
  );
};
