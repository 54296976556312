import React from 'react';
import { Modal } from 'antd';

export const confirmIndexChangeModal = ({
  state,
  stateTarget,
  callback,
}: {
  state: string;
  stateTarget: string;
  callback: () => void;
}) => {
  const content = (
    <div>
      <p>
        Do you want to change index state from <br /> <b>{state}</b> to{' '}
        <b>{stateTarget}</b>?
      </p>
    </div>
  );

  Modal.confirm({
    title: 'Index state change: ',
    content,
    onOk: callback,
    onCancel: () => {},
  });
};
