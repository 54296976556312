import styled from 'styled-components';
import { Select } from 'antd';

export const IndexStateSelect = styled(Select)`
  font-size: 13px;

  & > .ant-select-selector {
    border: none;
    background-color: #fcfcfc;

    & .ant-select-arrow {
      right: 8px;
    }
  }

  & .option-icon {
    font-size: 17px;
  }
`;
