import { useMutation } from '@apollo/react-hooks';
import {
  CalculateReservixApiDataDocument,
  CalculateReservixApiDataMutationResult,
  CalculateReservixApiDataMutationVariables,
  EventsQueryResult,
} from '../resolver.types';
import { useCallback, useEffect, useState } from 'react';
import usePrevious from './use-previous';
import isEqual from 'lodash.isequal';

type QueryResult = {
  [key: string]: EventsQueryResult;
};

const useCalculateEventsData = () => {
  const [eventIDs, setEventIDs] = useState<string[]>([]);
  const previousEventIDs = usePrevious(eventIDs);

  const [mutate, { data, loading }] = useMutation<
    CalculateReservixApiDataMutationResult['data'],
    CalculateReservixApiDataMutationVariables
  >(CalculateReservixApiDataDocument);

  const handleQueryChange = useCallback((data?: QueryResult) => {
    if (!data) {
      return setEventIDs([]);
    }

    const [result] = Object.values(data);

    if (!result || !result.items?.length) {
      return setEventIDs([]);
    }

    const eventIDs =
      result.items.map((event) => event?.id).filter(Boolean) ?? [];

    setEventIDs(eventIDs as string[]);
  }, []);

  useEffect(() => {
    if (!eventIDs.length || isEqual(eventIDs, previousEventIDs)) {
      return;
    }

    mutate({
      variables: {
        input: {
          ids: eventIDs,
        },
      },
    });
  }, [eventIDs, mutate, previousEventIDs]);

  return {
    data,
    loading,
    handleQueryChange,
  };
};

export default useCalculateEventsData;
