import { Col } from 'antd';
import React, { useCallback } from 'react';
import { AutoComplete } from '../../../filters/inputs/common/autocomplete/autocomplete.component';
import { Range } from '../../../filters/inputs/common/range/range.component';
import OrganizerInput from '../../../filters/inputs/event/organizer-input/organizer-input.component';
import EventCityOrZip from '../../../filters/inputs/event/event-city-or-zip/event-city-or-zip.component';
import Genres from '../../../filters/inputs/common/genres/genres.component';
import { noop } from '../../../../utils/text';
import {
  DataList,
  DataListProps,
} from '../../../common/data-list/data-list.component';
import { Event, GetFilterEventsDocument } from '../../../../resolver.types';
import styled from 'styled-components';
import { PlusOutlined } from '@ant-design/icons';
import { allEventTabColumns, defaultColumns } from './event-step-columns';
import useCalculateEventsData from '../../../../hooks/use-calculate-events-data';
import { customOrder } from './custom-order';
import { DateSelectWrapper } from '../../../filters/inputs/common/date-select/date-select.wrapper';
import TagsWrapper from '../../../filters/inputs/common/tags/tags-wrapper.component';

export type AssignEventsModalProps = {
  assignEventsAction: (events: Event[]) => void;
  disabled?: boolean;
  onClose?: () => any;
  omitEventIDs?: string[];
  brandedContainerId: string;
  openDetails: (event: Event) => void;
  loading?: boolean;
};

const AllEventsList = ({
  omitEventIDs = [],
  openDetails,
  assignEventsAction,
  loading,
  disabled,
}: AssignEventsModalProps) => {
  const { handleQueryChange } = useCalculateEventsData();

  const parseQuery: DataListProps<Event>['parseQueryVarsBeforeQuery'] =
    useCallback(
      (params: any) => {
        params.filters = {
          ...(params?.filters ?? {}),
          omitIDs: omitEventIDs,
        };

        return params;
      },
      [omitEventIDs]
    );

  return (
    <StyledAllEventsContainer>
      <DataList<Event>
        filters={
          !disabled
            ? [
                <Col key={1} xs={8}>
                  <AutoComplete
                    label="Event title"
                    name="title"
                    placeholder="Event title..."
                  />
                </Col>,
                <Col key={2} xs={8}>
                  <Range
                    label="Price"
                    suffix="€"
                    name="price"
                    onlyIntegers
                    placeholder="Price..."
                    disabled={true}
                    tooltipText="Filter is currently not available"
                  />
                </Col>,
                <Col key={3} xs={8}>
                  <DateSelectWrapper
                    name="eventPeriod"
                    placeholder="Event date..."
                    label="Event date"
                    timeSelection={false}
                  />
                </Col>,
                <Col key={4} xs={8}>
                  <OrganizerInput
                    mode="tags"
                    showReset
                    label="Organizer name or ID"
                    name="organizers"
                    placeholder="Organizer name or ID..."
                  />
                </Col>,
                <Col key={5} xs={8}>
                  <DateSelectWrapper
                    name="presaleStart"
                    placeholder="Presale start..."
                    label="Presale start"
                    timeSelection={false}
                  />
                </Col>,
                <Col key={6} xs={8}>
                  <AutoComplete
                    name="fullTextSearch"
                    placeholder="Full text search..."
                    label="Full text search"
                  />
                </Col>,
                <Col key={7} xs={8}>
                  <EventCityOrZip
                    label="City or postal code"
                    placeholder="City or postal code..."
                    showReset
                    name="location"
                  />
                </Col>,
                <Col key={8} xs={8}>
                  <Genres label="Genre" />
                </Col>,
                <Col key={9} xs={8}>
                  <TagsWrapper
                    label="Event ID"
                    name="eventID"
                    placeholder="Event ID..."
                  />
                </Col>,
                /**
                 * @note TicketSpecials feature temporary removed - CP-671
                 */
              ]
            : undefined
        }
        filterProps={{
          displayFiltersCount: 6,
        }}
        fetchPolicy="cache-and-network"
        useRowSelection={!disabled}
        parseQueryVarsBeforeQuery={parseQuery}
        rowSelectionPosition="sticky"
        rowSelectionActions={[
          {
            label: loading ? 'Assigning...' : 'Assign',
            icon: <PlusOutlined />,
            type: 'primary',
            onClick: assignEventsAction,
            loading,
          },
        ]}
        selectionLabelProvider={(count) => (
          <span>
            <strong>{count}</strong>
            {noop(count, ' event selected', ' events selected')}
          </span>
        )}
        columns={
          !disabled
            ? allEventTabColumns(assignEventsAction, loading, openDetails)
            : defaultColumns()
        }
        query={GetFilterEventsDocument}
        dataType="events"
        onQueryUpdate={handleQueryChange}
        dataWhichUpdateQueryParamsWhenChanged={omitEventIDs}
        customOrder={customOrder}
      />
    </StyledAllEventsContainer>
  );
};

export default AllEventsList;

const StyledAllEventsContainer = styled.div`
  & .ant-row {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  & .ant-legacy-form-item label {
    font-size: 11px;
    font-weight: normal;
    color: #6d6d6d;
  }

  & .ant-form-item label {
    font-size: 11px;
    font-weight: normal;
    color: #6d6d6d;
  }

  & .ant-input {
    height: auto;

    &[readonly] {
      background-color: transparent !important;
    }
  }
`;
