import styled from 'styled-components';

export const TopbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: #fcfcfc;
  padding: 18px 25px;
  border-bottom: 1px solid #f1f1f1;

  & > .left-column {
    display: flex;

    & > .title {
      margin-left: 15px;
    }
  }

  & > .right-column {
    margin-right: 30px;
    margin-top: 30px;
    display: flex;
    gap: 20px;
  }
`;
