import React, { useEffect, useCallback } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import { BrandName } from 'yggdrasil-shared/domain/dictionary';
import { brandCustom } from 'yggdrasil-shared/domain/brand-custom';
import {
  BcTranslation,
  BrandedContainerImage,
  BrandedContainerImageAspectRatio,
  GetActivityForBrandedContainerDocument,
  UpdateBrandedContainerInput,
} from '../../../resolver.types';
import {
  UPDATE_BRANDED_CONTAINER,
  UpdateBrandedContainerResult,
  UpdateBrandedContainerVariables,
} from '../../../graphql/mutations/update-branded-container';
import pickBy from 'lodash.pickby';
import { message } from 'antd';
import {
  InputError,
  VersionMismatchError,
} from 'yggdrasil-shared/domain/error';
import useVersionMismatchError from './use-version-mismatch-error';
import { stateChangeErrorModal } from '../state-switcher/helpers';
import { Lockers } from './use-fields-lockers';
import useAppContext from '../../../hooks/use-app-context';
import cloneDeep from 'lodash.clonedeep';

export type ValidationErrors = {
  [key: string]: string;
};

export type ValidationRule = {
  name: string;
  message: string;
  isValid: (value: any) => boolean;
};

type UseFormProps = {
  initialValues: UpdateBrandedContainerInput;
  validationRules?: ValidationRule[];
  afterSubmit?: (data: UpdateBrandedContainerInput | undefined) => void;
  onDirtyChange?: (isDirty: boolean) => void;
  resetLockersToDefault: () => void;
  compareAndResetTranslations: (initialValues: any, values: any) => any;
  lockers: Lockers;
};

const slugify = (value: string, brandName?: BrandName | null) => {
  return brandName ? brandCustom.slugify(brandName, value) : value;
};

export function useUpdateForm({
  initialValues,
  afterSubmit,
  validationRules,
  onDirtyChange,
  resetLockersToDefault,
  lockers,
  compareAndResetTranslations,
}: UseFormProps) {
  const {
    state: { selectedBrand },
  } = useAppContext();

  const selectedBrandName = selectedBrand?.name;

  const [values, setValues] = React.useReducer((state: any, newState: any) => {
    return {
      ...state,
      ...newState,
      ...(newState.region?.properties.radius && {
        regionRadius: newState.region?.properties.radius,
      }),
      ...(state.translations && {
        translations: cloneDeep(state.translations),
      }),
      ...(newState.translations && {
        translations: cloneDeep(newState.translations),
      }),
    };
  }, initialValues);

  const { setVersionMismatchError, ...versionMismatchHook } =
    useVersionMismatchError();

  const [errors, setErrors] = React.useState({});
  const [isDirty, toggleDirty] = React.useState(false);
  const [isLoading, toggleLoading] = React.useState(false);

  const [mutate] = useMutation<
    UpdateBrandedContainerResult,
    UpdateBrandedContainerVariables
  >(UPDATE_BRANDED_CONTAINER, {
    refetchQueries: [
      {
        query: GetActivityForBrandedContainerDocument,

        variables: {
          id: values.id,
          pagination: {
            start: 0,
            limit: 3,
          },
        },
      },
    ],
  });

  const onChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    if (event.persist) {
      event.persist();
    }

    const { name, value, dataset } = event.target;

    if (name === 'title' && !initialValues.title && lockers.titleSlug) {
      setValues({
        titleSlug: slugify(value, selectedBrandName),
      });
    }

    if (name === 'titleSlug' && !lockers.titleSlug) {
      const specialChars = selectedBrandName
        ? brandCustom.getSlugifySpecialChars(selectedBrandName)
        : undefined;

      const endChar =
        value.endsWith('-') ||
        value.endsWith(' ') ||
        (values?.titleSlug?.endsWith('-') &&
          specialChars?.test(value.slice(-1)))
          ? '-'
          : '';

      setValues({
        titleSlug: `${slugify(value, selectedBrandName)}${endChar}`,
      });
    } else if (name === 'regionRadius') {
      setValues({
        region: {
          features: values?.region?.features ?? [],
          properties: {
            ...values?.region?.properties,
            radius: parseInt(value, 10),
          },
        },
      });
    } else if (name === 'copyright') {
      const aspectRatio =
        dataset.aspectRatio as BrandedContainerImageAspectRatio;

      const images = [...values.images] as BrandedContainerImage[];
      const imageToUpdateIndex = images.findIndex(
        (image) => image.aspectRatio === aspectRatio
      );

      if (imageToUpdateIndex < 0) return;

      images[imageToUpdateIndex] = {
        ...images[imageToUpdateIndex],
        copyrightInformation: value,
      };

      setValues({ images });
    } else {
      setValues({ [name]: value });
    }

    toggleDirty(true);
  };

  const getValidationErrors = (): ValidationErrors => {
    const availableValidationRules = validationRules?.filter((rule) =>
      Object.keys(values).includes(rule.name)
    );

    if (!availableValidationRules) {
      return {};
    }

    return availableValidationRules.reduce((errors, rule) => {
      if (!rule.isValid(values[rule.name])) {
        return {
          ...errors,
          [rule.name]: rule.message,
        };
      }

      return errors;
    }, {});
  };

  const clearImageFormValue = (
    aspectRatio: BrandedContainerImageAspectRatio
  ) => {
    const clearedImages = values.images.filter(
      (image: BrandedContainerImage) => image.aspectRatio !== aspectRatio
    );

    setValues({ images: clearedImages });
  };

  const addImageFormValue = (imageToAdd: BrandedContainerImage) => {
    const existingImages = (
      Array.isArray(values.images) ? values.images : []
    ) as BrandedContainerImage[];

    const imagesToKeep = existingImages.filter(
      (existingImage) => existingImage.aspectRatio !== imageToAdd.aspectRatio
    );

    setValues({ images: [...imagesToKeep, imageToAdd] });
  };

  const refreshTitleSlug = useCallback(
    (titleSlug?: string | null) => {
      if (!titleSlug) {
        return titleSlug;
      }

      const refreshedTitleSlug = slugify(titleSlug, selectedBrandName);

      setValues({
        titleSlug: refreshedTitleSlug,
      });

      return refreshedTitleSlug;
    },
    [selectedBrandName]
  );

  const pickWithValues = (obj: object) =>
    pickBy(obj, (value) => (value !== undefined ? value !== null : true));

  const onSubmit = async () => {
    const updatingDataKey = 'updating-branded-container';
    const validationErrors = getValidationErrors();

    if (Object.keys(validationErrors).length) {
      setErrors(validationErrors);
      message.error('There are validation errors, please fix them.');
      return;
    }

    message.loading({
      key: updatingDataKey,
      content: 'Updating branded container data...',
    });

    const {
      id,
      version,
      __typename,
      state,
      brands,
      brand,
      dataLastUpdated,
      lastUpdatedAuthor,
      updatedAt,
      images,
      topic,
      comments,
      showOnMainPage,
      showOnEventCharts,
      thumbnails,
      cropBoundaries,
      region,
      suggestedEventFilters,
      lastSuggestedEventsUpdate,
      suggestedEventsTotal,
      titleSlug,
      translations,
      isIndexable,
      ...rest
    } = values;

    const refreshedTitleSlug = refreshTitleSlug(titleSlug);

    try {
      toggleLoading(true);
      const { data } = await mutate({
        variables: {
          brandedContainer: {
            id,
            version,
            showOnMainPage: Boolean(showOnMainPage),
            isIndexable: Boolean(isIndexable),
            showOnEventCharts: Boolean(showOnEventCharts),
            images: images.map((image: BrandedContainerImage) => ({
              aspectRatio: image.aspectRatio,
              copyrightInformation: image.copyrightInformation,
              url: image.url.split('/').pop(),
              mimetype: image.mimetype,
            })),
            translations: compareAndResetTranslations(
              initialValues,
              values
            ).map((translation: BcTranslation) => {
              const { __typename, dataLastUpdated, ...rest } = translation;
              return {
                ...pickWithValues(rest),
              };
            }),
            ...pickWithValues({ ...rest, titleSlug: refreshedTitleSlug }),
          },
        },
      });

      setValues({ version: data?.updateBrandedContainer.version });

      message.success({
        key: updatingDataKey,
        content: 'Success.',
        duration: 3,
      });

      if (afterSubmit) {
        afterSubmit(data?.updateBrandedContainer);
      }

      toggleDirty(false);
      resetLockersToDefault();
    } catch (e) {
      const apolloError = e as ApolloError;
      const [error] = apolloError.graphQLErrors;
      if (error.name === 'VersionMismatchError') {
        message.destroy();
        setVersionMismatchError(error as unknown as VersionMismatchError);
      } else {
        stateChangeErrorModal(error as InputError);
        setVersionMismatchError(null);
      }
    } finally {
      setErrors({});
      toggleLoading(false);
    }
  };

  const discardChanges = () => {
    setValues(initialValues);
    toggleDirty(false);
    toggleLoading(false);
    resetLockersToDefault();
  };

  useEffect(() => {
    if (onDirtyChange) {
      onDirtyChange(isDirty);
    }
  }, [isDirty, onDirtyChange]);

  return {
    values,
    onChange,
    onSubmit,
    setValues,
    isDirty,
    isLoading,
    discardChanges,
    errors,
    setVersionMismatchError,
    toggleDirty,
    clearImageFormValue,
    addImageFormValue,
    ...versionMismatchHook,
  };
}
