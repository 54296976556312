import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
  useContext,
} from 'react';
import {
  AssignEventsDocument,
  AssignEventsMutationResult,
  AssignEventsMutationVariables,
  AssignEventsToBcInput,
  CalculateEventSuggestionsDocument,
  Event,
  GetBrandedContainerDocument,
  RemoveAssignedEventsDocument,
  RemoveAssignedEventsMutationResult,
  RemoveAssignedEventsMutationVariables,
  UpdateBrandedContainerEventFiltersDocument,
  DeclineEventsDocument,
  DeclineEventsMutationResult,
  DeclineEventsMutationVariables,
  RestoreDeclinedEventsMutationResult,
  RestoreDeclinedEventsMutationVariables,
  RestoreDeclinedEventsDocument,
} from '../../../../resolver.types';
import { EventsType } from '../../../event/events-switcher/events-switcher.component';
import { useMutation } from '@apollo/client';
import { DefaultEventList } from './default-event-list.component';
import { SuggestedEventList } from './suggested-event-list.component';
import { DeclinedEventList } from './declined-event-list.component';
import { EventsFilterButtons } from '../../../event/events-filter-buttons/events-filter-buttons.component';
import {
  EventFiltersContext,
  EventFiltersStoreApi,
} from '../../../../context/event-filters.context';
import { buildEventFilters } from '../../../../utils/event-filters/build-event-filters';
import { getInitialEventFilters } from '../../../../utils/event-filters/get-initial-event-filters';
import styled from 'styled-components';
import { SpinLoader } from '../../../common/spin-loader/spin-loader.component';
import {
  InputError,
  VersionMismatchError,
} from 'yggdrasil-shared/domain/error';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Modal, Tabs } from 'antd';
import type { ApolloError } from '@apollo/client';
import { useNavigate, Routes, useLocation, Route } from 'react-router';
import AllEventsList from './all-events.component';
import { routes } from '../../../../route-urls';
import EventDetailsDrawer from './event-details-drawer.component';
import { message } from 'antd';
import type { EventDetailsAction } from '../../../event/drawer-content/shared/types';
import { getSuggestedEventsCount } from '../../../event/suggested-events-count/suggested-events-count.component';

type EventsStepProps = {
  brandedContainerId: string;
  version: number;
  eventIDs: string[];
  suggestedIDs: string[];
  suggestedEventsTotal: number;
  declinedIDs: string[];
  disabled?: boolean;
  setVersionMismatch?: (error: VersionMismatchError | null) => any;
};

const StyledSpinner = styled(SpinLoader)`
  height: 50px;
  margin-bottom: 20px;
`;

export const EventsStep = ({
  brandedContainerId,
  version,
  eventIDs,
  suggestedIDs,
  suggestedEventsTotal,
  declinedIDs,
  disabled = false,
  setVersionMismatch,
}: EventsStepProps) => {
  const location = useLocation() as {
    state: { tab?: EventsType };
    pathname: string;
  };

  const navigate = useNavigate();

  const [eventsType, setEventsType] = useState<EventsType>(
    location.state?.tab ?? EventsType.Default
  );

  const [showFilters, setShowFilters] = useState(false);

  const toggleFilters = useCallback(() => {
    setShowFilters(!showFilters);
  }, [showFilters]);

  const [detailsActions, setDetailsActions] = useState<EventDetailsAction[]>(
    []
  );

  const closeDetailsOpen = useCallback(() => {
    navigate(routes.setBrandedContainerData(brandedContainerId, 'bc-events'));
    setDetailsActions([]);
  }, [brandedContainerId, navigate]);

  const isSuggestedEventsFetched = useRef(false);

  const {
    state: { selectedFilters },
    dispatch,
  } = useContext(EventFiltersContext) as EventFiltersStoreApi;

  const [
    assignEventsMutation,
    { loading: assignEventsLoading, error: assignEventsError },
  ] = useMutation<
    AssignEventsMutationResult['data'],
    AssignEventsMutationVariables
  >(AssignEventsDocument, {
    awaitRefetchQueries: true,
  });

  const [
    calculateEventSugestions,
    { loading: fetchEventSuggestionsLoading, error: calculateError },
  ] = useMutation(CalculateEventSuggestionsDocument, {
    variables: {
      input: {
        version,
        id: brandedContainerId,
      },
    },
    refetchQueries: [
      {
        query: GetBrandedContainerDocument,
        variables: { id: brandedContainerId },
      },
    ],
    awaitRefetchQueries: true,
  });

  React.useEffect(() => {
    if (isSuggestedEventsFetched.current) {
      return;
    }

    isSuggestedEventsFetched.current = true;
    calculateEventSugestions();
  });

  const [updateFilters, { error: updateBcFiltersError }] = useMutation(
    UpdateBrandedContainerEventFiltersDocument,
    {
      refetchQueries: [
        {
          query: GetBrandedContainerDocument,
          variables: { id: brandedContainerId },
        },
      ],
      awaitRefetchQueries: true,
    }
  );

  const [
    removeAssignedEvents,
    { loading: removeAssignedEventsLoading, error: removeAssignedEventsError },
  ] = useMutation<
    RemoveAssignedEventsMutationResult['data'],
    RemoveAssignedEventsMutationVariables
  >(RemoveAssignedEventsDocument, {
    refetchQueries: [
      {
        query: GetBrandedContainerDocument,
        variables: { id: brandedContainerId },
      },
    ],
    awaitRefetchQueries: true,
  });

  const [
    declineEvents,
    { loading: declineEventsLoading, error: declineEventsError },
  ] = useMutation<
    DeclineEventsMutationResult['data'],
    DeclineEventsMutationVariables
  >(DeclineEventsDocument, {
    refetchQueries: [
      {
        query: GetBrandedContainerDocument,
        variables: { id: brandedContainerId },
      },
    ],
    awaitRefetchQueries: true,
  });

  const [
    restoreDeclinedEvents,
    {
      loading: restoreDeclinedEventsLoading,
      error: restoreDeclinedEventsError,
    },
  ] = useMutation<
    RestoreDeclinedEventsMutationResult['data'],
    RestoreDeclinedEventsMutationVariables
  >(RestoreDeclinedEventsDocument, {
    refetchQueries: [
      {
        query: GetBrandedContainerDocument,
        variables: { id: brandedContainerId },
      },
    ],
    awaitRefetchQueries: true,
  });

  const loadingCondition =
    declineEventsLoading ||
    removeAssignedEventsLoading ||
    fetchEventSuggestionsLoading ||
    assignEventsLoading ||
    restoreDeclinedEventsLoading;

  const removeEvents = useCallback(
    async (events: Event[]) => {
      if (removeAssignedEventsLoading) {
        return;
      }

      const removingEventsKey = 'removing-events-from-bc';
      const eventsToRemove = events.map((event) => event.id);

      message.loading({
        key: removingEventsKey,
        content: 'Removing event(s)...',
      });

      try {
        const { data } = await removeAssignedEvents({
          variables: {
            input: {
              brandedContainerIDs: [brandedContainerId],
              eventIDs: eventsToRemove,
            },
          },
        });

        message.success({
          key: removingEventsKey,
          content: 'Success! Event(s) removed.',
          duration: 3,
        });

        calculateEventSugestions({
          variables: {
            input: {
              version: data!.removeAssignedEvents[0].version,
              id: brandedContainerId,
            },
          },
        });
      } catch (err) {
        message.destroy();
        throw err;
      }
    },
    [
      removeAssignedEvents,
      brandedContainerId,
      removeAssignedEventsLoading,
      calculateEventSugestions,
    ]
  );

  const declineSuggestedEvents = useCallback(
    async (events: Event[]) => {
      if (removeAssignedEventsLoading) {
        return;
      }

      const eventsToDecline = events.map((event) => event.id);

      const decliningEventsKey = 'declining-events-from-bc';

      message.loading({
        key: decliningEventsKey,
        content: 'Declining event(s)...',
      });

      try {
        const { data } = await declineEvents({
          variables: {
            input: {
              brandedContainerIDs: [brandedContainerId],
              eventIDs: eventsToDecline,
            },
          },
        });

        message.success({
          key: decliningEventsKey,
          content: 'Success! Event(s) declined.',
          duration: 3,
        });

        calculateEventSugestions({
          variables: {
            input: {
              version: data!.declineEvents[0].version,
              id: brandedContainerId,
            },
          },
        });
      } catch (err) {
        message.destroy();
        throw err;
      }
    },
    [
      declineEvents,
      brandedContainerId,
      calculateEventSugestions,
      removeAssignedEventsLoading,
    ]
  );

  const restoreDeclinedEventsAction = useCallback(
    async (events: Event[]) => {
      if (removeAssignedEventsLoading) {
        return;
      }

      const eventsToRestore = events.map((event) => event.id);

      const restoringDeclinedEventsKey = 'restoring-declined-events-from-bc';

      message.loading({
        key: restoringDeclinedEventsKey,
        content: 'Restoring declined event(s)...',
      });

      try {
        const { data } = await restoreDeclinedEvents({
          variables: {
            input: {
              brandedContainerIDs: [brandedContainerId],
              eventIDs: eventsToRestore,
            },
          },
        });

        message.success({
          key: restoringDeclinedEventsKey,
          content: 'Success! Event(s) restored.',
          duration: 3,
        });

        calculateEventSugestions({
          variables: {
            input: {
              version: data!.restoreDeclinedEvents[0].version,
              id: brandedContainerId,
            },
          },
        });
      } catch (err) {
        message.destroy();
        throw err;
      }
    },
    [
      brandedContainerId,
      calculateEventSugestions,
      removeAssignedEventsLoading,
      restoreDeclinedEvents,
    ]
  );

  const assignEvents = useCallback(
    async (events: Event[]) => {
      if (loadingCondition) {
        return;
      }

      const assigningEventsKey = 'assigning-events-to-bc';

      const eventIDsToAssign = events.map((event) => event.id);

      const input: AssignEventsToBcInput = {
        eventIDs: eventIDsToAssign,
        brandedContainerIDs: [brandedContainerId],
      };

      message.loading({
        key: assigningEventsKey,
        content: 'Assigning event(s) to branded container...',
      });

      try {
        const { data } = await assignEventsMutation({
          variables: {
            input,
          },
        });

        message.success({
          key: assigningEventsKey,
          content: 'Success! Event(s) assigned.',
          duration: 3,
        });

        await calculateEventSugestions({
          variables: {
            input: {
              version: data!.assignEvents[0].version,
              id: brandedContainerId,
            },
          },
        });
      } catch (err) {
        message.destroy();
        throw err;
      }
    },
    [
      assignEventsMutation,
      brandedContainerId,
      calculateEventSugestions,
      loadingCondition,
    ]
  );

  const onApplyFilters = async () => {
    dispatch({
      type: 'TOGGLE_LOADING',
      loading: true,
    });

    try {
      const { data } = await updateFilters({
        variables: {
          input: {
            version,
            id: brandedContainerId,
            filters: JSON.stringify(buildEventFilters(selectedFilters)),
          },
        },
      });

      await calculateEventSugestions({
        variables: {
          input: {
            version: data.updateEventFilters.version,
            id: brandedContainerId,
          },
        },
      });

      dispatch({
        type: 'UPDATE_INITIAL_FILTERS',
        filters: getInitialEventFilters(
          JSON.parse(data.updateEventFilters.suggestedEventFilters)
        ),
      });
    } finally {
      dispatch({
        type: 'TOGGLE_LOADING',
        loading: false,
      });
    }
  };

  const onResetFilters = () => {
    dispatch({
      type: 'RESET_FILTERS',
    });
  };

  const handleError = useCallback(
    (apolloError: ApolloError) => {
      const [error] = apolloError.graphQLErrors;

      if (error.name === 'VersionMismatchError' && setVersionMismatch) {
        setVersionMismatch(error as unknown as VersionMismatchError);
      } else {
        Modal.error({
          title: 'Event suggestions error',
          content: (
            <>
              <p style={{ whiteSpace: 'pre-line' }}>{error.message}</p>
              {'details' in error && (
                <ul>
                  {Object.values((error as InputError).details || []).map(
                    ({ message, context: { key } }: any) => (
                      <li key={key}>{message}</li>
                    )
                  )}
                </ul>
              )}
            </>
          ),
        });
      }
    },
    [setVersionMismatch]
  );

  useEffect(() => {
    if (calculateError) {
      handleError(calculateError);
    }
  }, [handleError, calculateError]);

  useEffect(() => {
    if (removeAssignedEventsError) {
      handleError(removeAssignedEventsError);
    }
  }, [handleError, removeAssignedEventsError]);

  useEffect(() => {
    if (declineEventsError) {
      handleError(declineEventsError);
    }
  }, [handleError, declineEventsError]);

  useEffect(() => {
    if (restoreDeclinedEventsError) {
      handleError(restoreDeclinedEventsError);
    }
  }, [handleError, restoreDeclinedEventsError]);

  useEffect(() => {
    if (updateBcFiltersError) {
      handleError(updateBcFiltersError);
    }
  }, [handleError, updateBcFiltersError]);

  useEffect(() => {
    if (assignEventsError) {
      handleError(assignEventsError);
    }
  }, [handleError, assignEventsError]);

  const assignAction: EventDetailsAction = useMemo(
    () => ({
      label: 'Assign',
      icon: <PlusOutlined />,
      type: 'primary',
      onClick: assignEvents,
      loading: loadingCondition,
    }),
    [assignEvents, loadingCondition]
  );

  const removeAction: EventDetailsAction = useMemo(
    () => ({
      label: 'Remove',
      icon: <CloseOutlined />,
      type: 'primary',
      onClick: removeEvents,
      loading: loadingCondition,
    }),
    [removeEvents, loadingCondition]
  );

  const restoreAction: EventDetailsAction = useMemo(
    () => ({
      label: 'Restore',
      icon: <CloseOutlined />,
      type: 'primary',
      onClick: restoreDeclinedEventsAction,
      loading: loadingCondition,
    }),
    [restoreDeclinedEventsAction, loadingCondition]
  );

  const declineAction: EventDetailsAction = useMemo(
    () => ({
      label: 'Decline',
      icon: <CloseOutlined />,
      type: 'default',
      onClick: declineSuggestedEvents,
      loading: loadingCondition,
    }),
    [declineSuggestedEvents, loadingCondition]
  );

  const defaultDetailsActions: EventDetailsAction[] = useMemo(
    () => [removeAction],
    [removeAction]
  );

  const suggestedDetailsActions: EventDetailsAction[] = useMemo(
    () => [assignAction, declineAction],
    [assignAction, declineAction]
  );

  const declinedDetailsActions: EventDetailsAction[] = useMemo(
    () => [restoreAction],
    [restoreAction]
  );

  const allEventsDetailsActions: EventDetailsAction[] = useMemo(
    () => [assignAction],
    [assignAction]
  );

  const goToDetailView = React.useCallback(
    (record: Event) => {
      navigate(routes.bcEventsDetailView(brandedContainerId, record.id));
    },
    [brandedContainerId, navigate]
  );

  const openDetails = (actions: EventDetailsAction[]) => (record: Event) => {
    setDetailsActions(actions);
    goToDetailView(record);
  };

  useEffect(() => {
    setDetailsActions(
      detailsActions.map((action) => {
        return {
          ...action,
          loading: loadingCondition,
        };
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingCondition]);

  return (
    <Container>
      <Routes>
        <Route
          path={`:id/*`}
          element={
            <EventDetailsDrawer
              handleClose={closeDetailsOpen}
              actions={detailsActions}
            />
          }
        />
      </Routes>
      {fetchEventSuggestionsLoading && (
        <StyledSpinner loadingMessage="Fetching newest event suggestions..." />
      )}
      <div>
        <Tabs
          animated={false}
          destroyInactiveTabPane
          activeKey={eventsType}
          onChange={setEventsType as (tab: string) => any}
        >
          <Tabs.TabPane
            tab={`Assigned events (${eventIDs.length})`}
            key={EventsType.Default}
          >
            <DefaultEventList
              removeEventsAction={removeEvents}
              eventIDs={eventIDs}
              loading={loadingCondition}
              disabled={disabled}
              openDetails={openDetails(defaultDetailsActions)}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={getSuggestedEventsCount(suggestedEventsTotal, false)}
            key={EventsType.Suggested}
          >
            <>
              {!disabled && (
                <React.Fragment>
                  <EventsFilterButtons
                    onReset={onResetFilters}
                    onActionClick={toggleFilters}
                    onApply={onApplyFilters}
                    suggestedEventsTotal={suggestedEventsTotal}
                  />
                </React.Fragment>
              )}
              <SuggestedEventList
                declineEventsAction={declineSuggestedEvents}
                assignEventsAction={assignEvents}
                eventIDs={suggestedIDs}
                loading={loadingCondition}
                disabled={disabled}
                openDetails={openDetails(suggestedDetailsActions)}
              />
            </>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={`Declined events (${declinedIDs.length})`}
            key={EventsType.Declined}
          >
            <DeclinedEventList
              restoreDeclinedEventsAction={restoreDeclinedEventsAction}
              eventIDs={declinedIDs}
              loading={loadingCondition}
              disabled={disabled}
              openDetails={openDetails(declinedDetailsActions)}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab={`All events`} key={EventsType.All}>
            <AllEventsList
              assignEventsAction={assignEvents}
              brandedContainerId={brandedContainerId}
              omitEventIDs={[...eventIDs, ...declinedIDs, ...suggestedIDs]}
              disabled={disabled}
              openDetails={openDetails(allEventsDetailsActions)}
              loading={loadingCondition}
            />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: calc(100% - 65px);

  .ant-tabs-tab {
    font-size: 12px;
    margin: 0 32px 0 0;
    padding: 12px 16px;
  }

  .ant-tabs-bar {
    margin-bottom: 0;
  }
`;
