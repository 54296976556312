import * as React from 'react';
import { BrandedContainerTitle } from '../branded-container-title/branded-container-title.component';
import { StateSwitcher } from '../state-switcher/state-switcher.component';
import { TopbarContainer } from '../../layout/drawer/drawer-topbar.styles';
import { Thumbnail } from '../../common/thumbnail/thumbnail.component';
import pickby from 'lodash.pickby';
import { Except } from 'type-fest';
import { Topic, TopicData } from '../../../resolver.types';
import { getTitle } from '../../../utils/branded-container';
import { useMemo } from 'react';
import { Tooltip } from 'antd';
import { IndexSwitcher } from '../indexed-switcher/indexer-switcher.component';

export type TopicResult = Except<Topic, 'data'> & {
  data: TopicData & { __typename?: string };
};

type DrawerTobparProps = {
  brandedContainerId: string;
  onChangeRelevance: (relevance: number) => void;
  onStateChange?: () => void;
  onIndexStateChange: (indexState: boolean) => Promise<void>;
  relevance?: number;
  topic: TopicResult;
  disabled?: boolean;
  thumbnailUrl?: string;
  isFormDisabled?: boolean;
  updateLoading: boolean;
  isDirty: boolean;
};

export const DrawerTopBar = ({
  onChangeRelevance,
  onStateChange,
  onIndexStateChange,
  brandedContainerId,
  relevance,
  topic: {
    data: { __typename, ...rest },
  },
  disabled,
  thumbnailUrl,
  updateLoading,
  isDirty,
  isFormDisabled,
}: DrawerTobparProps) => {
  const topicData = pickby(rest, (value) => Boolean(value)) as {
    key: string | string[];
  };

  const title = useMemo(() => getTitle(topicData), [topicData]);

  return (
    <TopbarContainer>
      <div className="left-column">
        <Thumbnail brandSource="user" imageUrl={thumbnailUrl} />
        <BrandedContainerTitle
          disabled={disabled || isFormDisabled || updateLoading}
          topic={rest as TopicData}
          relevanceValue={relevance}
          onChangeRelevance={onChangeRelevance}
          brandedContainerTitle={title}
        />
      </div>
      <div className="right-column">
        <Tooltip
          title={
            isDirty
              ? 'You have to save your changes first.'
              : 'This will only affect published Branded Containers.'
          }
          placement="topRight"
        >
          <div className="index-state-switcher">
            <IndexSwitcher
              disabled={isDirty || isFormDisabled}
              brandedContainerId={brandedContainerId}
              onIndexStateChange={onIndexStateChange}
            />
          </div>
        </Tooltip>
        <Tooltip
          title={isDirty ? 'You have to save your changes first.' : ''}
          placement="bottomLeft"
        >
          <div className="state-switcher">
            <StateSwitcher
              disabled={disabled || isDirty}
              brandedContainerId={brandedContainerId}
              onStateChange={onStateChange}
            />
          </div>
        </Tooltip>
      </div>
    </TopbarContainer>
  );
};
