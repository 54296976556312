import * as React from 'react';
import { ModalCtaButton, StyledModal } from '../../layout/modal/modal.styles';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { SetPromotionItemModalHeader } from '../set-promotion-item-modal/set-promotion-item.styles';
import { TopicData } from '../../../pages/create-branded-container-from-topic/create-branded-container-from-topic.page';
import { BrandedContainer } from '../../../resolver.types';
import BrandedContainersWithSimilarTopicExistsWarning from '../branded-container-with-same-topic-exists-warning/branded-container-with-same-topic-exists-warning';
import { Tag } from 'antd';
import { StyledForm } from '../../artist/form-modal/form-modal.styles';

type SimilarBrandedContainersModalProps = {
  onClick: () => void;
  onCancel: () => void;
  onGoBack: () => void;
  visible: boolean;
  topicData: TopicData | undefined;
  onTopicDataSet: (topicData: TopicData) => Promise<void>;
  similarBrandedContainers: BrandedContainer[];
  isLoading: boolean;
};

export const SimilarBrandedContainersModal = ({
  onCancel,
  onGoBack,
  visible,
  topicData,
  onTopicDataSet,
  similarBrandedContainers,
  isLoading,
}: SimilarBrandedContainersModalProps) => {
  const similarBrandedContainersWarning = React.useMemo(
    () => (
      <Tag
        color="orange"
        style={{
          display: 'block',
          marginBottom: '8px',
          maxWidth: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
        }}
      >
        <BrandedContainersWithSimilarTopicExistsWarning
          brandedContainers={similarBrandedContainers}
          topicData={topicData}
        />
      </Tag>
    ),
    [similarBrandedContainers, topicData]
  );

  const [buttonClicked, setIsButtonClicked] = React.useState(false);

  const onClick = async () => {
    if (isLoading || buttonClicked) return;

    setIsButtonClicked(true);
    await onTopicDataSet(topicData!);
    setIsButtonClicked(false);
  };

  return (
    <StyledModal
      hasCustomHeightStyle
      visible={visible}
      maskClosable={false}
      onCancel={onCancel}
      title={
        <SetPromotionItemModalHeader>
          <ArrowLeftOutlined onClick={onGoBack} />
          Similar Branded Container
        </SetPromotionItemModalHeader>
      }
      footer={
        <ModalCtaButton
          onClick={onClick}
          disabled={buttonClicked || isLoading}
          loading={isLoading}
          type="primary"
        >
          <ArrowRightOutlined />
          Create
        </ModalCtaButton>
      }
    >
      <StyledForm>{similarBrandedContainersWarning}</StyledForm>
    </StyledModal>
  );
};

export default SimilarBrandedContainersModal;
