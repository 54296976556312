import { Except } from 'type-fest';
import {
  Multiselect,
  MultiSelectProps,
} from '../../common/multiselect/multiselect.component';
import { LabeledValue } from 'antd/lib/select';
import { camelCaseToSentence } from '../../../../../utils/text';
import React from 'react';
import { BrandedContaierIndex } from '../../../../../resolver.types';
import indexMap from './index-map';

export type SearchByIndexedComponentProps = Except<
  MultiSelectProps,
  'choices' | 'perPage' | 'showSearch'
>;

/**
 * @note "value" has been added - required by antd v4
 */
// @ts-ignore
const choices: LabeledValue[] = Object.values(BrandedContaierIndex).map(
  (value) => ({
    key: value,
    label: indexMap[value] ?? camelCaseToSentence(value),
  })
);

const SearchByIndexedComponent = (props: SearchByIndexedComponentProps) => {
  return (
    <Multiselect
      maxTagWidth="110px"
      maxTagCount={1}
      choices={choices}
      showSearch={false}
      perPage={10}
      initialValue={choices}
      {...props}
    />
  );
};

export default SearchByIndexedComponent;
