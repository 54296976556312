import { BrandedContaierIndex } from '../../../../../resolver.types';

export type IndexMap = {
  [Key in BrandedContaierIndex]?: string;
};

const indexMap: IndexMap = {
  [BrandedContaierIndex.Indexed]: 'Index',
  [BrandedContaierIndex.NotIndexed]: 'No Index',
};

export default indexMap;
