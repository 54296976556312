import React from 'react';
import { BrandedContainerDrawerNavigation } from '../drawer-navigation/drawer-navigation.component';
import { useParams } from 'react-router-dom';
import {
  BrandedContainer,
  GetActivityForBrandedContainerQuery,
  GetBrandedContainerDocument,
  GetBrandsByTopicDocument,
  GetByTopicAndBrandDocument,
} from '../../../resolver.types';
import { UpdateDataFormContainer } from '../update-data-form/update-data-form-container.component';
import { useLazyQuery, useQuery } from '@apollo/client';
import { Brand } from 'yggdrasil-shared/domain/dictionary';
import { SpinContainer } from '../../common/spin-loader/spin-loader.styles';
import { LastModifiedCommentList } from '../last-branded-container-comments/last-branded-container-comments.component';
import { LastModifiedList } from '../../common/last-modified-list/last-modified-list.component';
import {
  ContentContainer,
  DrawerContentWrapper,
} from '../../layout/drawer/drawer.styles';
import { MenuContainer } from './drawer-content.styles';
import type { BrandedContainerDetailsAction } from './shared/types';
import { DrawerActionsBar } from '../../common/drawer-actions-bar/drawer-actions-bar.component';
import { BcTranslation } from 'yggdrasil-shared/domain/private-api';

type BrandedContainerDrawerContentProps = {
  topicId: string;
  onDirtyChange?: (isDirty: boolean) => void;
  isFormDisabled?: boolean;
  onBrandChange?: (brand: string) => any;
  activityLogData: GetActivityForBrandedContainerQuery | undefined;
  isNestedDrawer?: boolean;
  actions?: BrandedContainerDetailsAction[];
  handleClose: () => void;
};

export type translationDateLastUpdated = {
  title?: string;
  subtitle?: string;
  description?: string;
  shortDescription?: string;
  pressText?: string;
  metaTitle?: string;
  metaDescription?: string;
};

export type BrandedContainerData = BrandedContainer & {
  brands: Brand[];
  translations: BcTranslation[];
};
export const BrandedContainerDrawerContent = ({
  topicId,
  onDirtyChange,
  isFormDisabled = false,
  onBrandChange,
  activityLogData,
  isNestedDrawer,
  actions,
  handleClose,
}: BrandedContainerDrawerContentProps) => {
  const { id } = useParams<{ id: string }>();
  const [brandedContainerData, setBrandedContainerData] =
    React.useState<BrandedContainerData | null>(null);

  const [totalRelatedBrandedContainers, setTotalRelatedBrandedContainers] =
    React.useState(0);

  const [totalEvents, setTotalEvents] = React.useState(0);

  const { data } = useQuery(GetBrandedContainerDocument, {
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
  });

  const [fetchSelectedBC, { data: selectedBrandData }] = useLazyQuery(
    GetByTopicAndBrandDocument,
    {
      fetchPolicy: 'network-only',
    }
  );

  const { data: brands } = useQuery(GetBrandsByTopicDocument, {
    variables: {
      id: topicId,
    },
    fetchPolicy: 'network-only',
  });

  const handleBrandChange = (brand: string) => {
    fetchSelectedBC({
      variables: {
        input: {
          brand,
          topicID: topicId,
        },
      },
    });

    if (onBrandChange) {
      onBrandChange(brand);
    }
  };

  React.useEffect(() => {
    if (data && brands) {
      setBrandedContainerData({
        ...data.brandedContainer,
        brands: brands.getBrandsByTopic,
      });

      setTotalEvents(data.brandedContainer.eventIDs.length);
      setTotalRelatedBrandedContainers(
        data.brandedContainer.relatedContainerIDs?.length ?? 0
      );
    }
  }, [data, brands]);

  React.useEffect(() => {
    if (selectedBrandData && brands) {
      setBrandedContainerData({
        ...selectedBrandData.getByTopicAndBrand,
        brands: brands.getBrandsByTopic,
      });

      setTotalEvents(selectedBrandData.getByTopicAndBrand.eventIDs.length);
      setTotalRelatedBrandedContainers(
        selectedBrandData.getByTopicAndBrand.relatedContainerIDs?.length ?? 0
      );
    }
  }, [selectedBrandData, brands]);

  return (
    <>
      {brandedContainerData && brandedContainerData.brands ? (
        <DrawerContentWrapper>
          <MenuContainer>
            <BrandedContainerDrawerNavigation
              totalEvents={totalEvents}
              totalRelatedBrandedContainers={totalRelatedBrandedContainers}
            />
            <LastModifiedList
              brandedContainerId={brandedContainerData.id}
              activityLog={
                activityLogData?.getActivityForBrandedContainer.items || []
              }
              totalActivities={
                activityLogData?.getActivityForBrandedContainer.metadata
                  .total ?? 0
              }
            />
            <LastModifiedCommentList
              comments={brandedContainerData.comments || []}
              brandedContainerId={brandedContainerData.id}
            />
          </MenuContainer>
          <ContentContainer>
            <UpdateDataFormContainer
              isFormDisabled={isFormDisabled}
              isNestedDrawer={isNestedDrawer}
              onDirtyChange={onDirtyChange}
              onChangeBrand={handleBrandChange}
              data={brandedContainerData}
            />
            {actions && (
              <DrawerActionsBar
                actions={actions}
                description="1 branded container selected"
                callback={handleClose}
                data={brandedContainerData}
              />
            )}
          </ContentContainer>
        </DrawerContentWrapper>
      ) : (
        <SpinContainer />
      )}
    </>
  );
};
