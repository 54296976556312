import React from 'react';
import { EventList } from './event-list.component';
import { defaultColumns } from './event-step-columns';
import { Event } from '../../../../resolver.types';
import useCalculateEventsData from '../../../../hooks/use-calculate-events-data';
import { CloseOutlined } from '@ant-design/icons';
import { noop } from '../../../../utils/text';

type DefaultEventProps = {
  eventIDs: string[];
  removeEventsAction: (events: Event[]) => void;
  loading?: boolean;
  disabled?: boolean;
  openDetails: (event: Event) => void;
};

export const DefaultEventList = ({
  eventIDs,
  removeEventsAction,
  loading,
  disabled = false,
  openDetails,
}: DefaultEventProps) => {
  const { handleQueryChange } = useCalculateEventsData();
  const rowSelection = React.useMemo(
    () => ({
      useRowSelection: true,
      rowSelectionActions: [
        {
          label: 'Remove',
          icon: <CloseOutlined />,
          type: 'primary',
          onClick: removeEventsAction,
          resetSelection: true,
          loading,
        },
      ],
      selectionLabelProvider: (count: number) => (
        <span>
          Remove selected {noop(count, ' event ', ' events ')}{' '}
          <strong>({count})</strong>?
        </span>
      ),
      rowSelectionColPush: 0,
    }),
    [removeEventsAction, loading]
  );

  return (
    <EventList
      onQueryUpdate={handleQueryChange}
      columns={disabled ? defaultColumns() : defaultColumns(openDetails)}
      rowSelection={disabled ? undefined : rowSelection}
      ids={eventIDs}
    />
  );
};
